import React from 'react'
import { P } from '@farewill/ui'
import PropTypes from 'prop-types'

import { API_ERROR_KEY } from '../constants'

const Errors = ({ errors }) => {
  const messages = Object.entries(errors).map(([id, text]) => ({ id, text }))

  const handleClick = (id) => (event) => {
    event.preventDefault()
    document.getElementById(id)?.focus()
  }

  return (
    <>
      {messages.length > 1 && (
        <P margin={[0, 0, 'XS']}>Please fix these errors to continue:</P>
      )}
      <ul>
        {messages.map(({ id, text }, index) => (
          <li key={index}>
            {id === API_ERROR_KEY ? (
              text
            ) : (
              <a href={`#${id}`} onClick={handleClick(id)}>
                {text}
              </a>
            )}
          </li>
        ))}
      </ul>
    </>
  )
}

Errors.propTypes = {
  errors: PropTypes.object.isRequired,
}

export default Errors
