/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { CompatRoute } from 'react-router-dom-v5-compat'

import { setScrollPosition } from 'lib/window'
import { closeMobileNav } from 'redux/showMobileNav/actionCreators'
import { closeAccountMenu } from 'redux/showAccountMenu/actionCreators'

import history from 'startup/common/history'

import AboutYourselfRouteHandler from 'ui/routes/about-yourself'
import AccountRouteHandler from 'ui/routes/account'
import AccountsAndPropertyRouteHandler from 'ui/routes/accounts-and-property'
import CapacityCheckRouteHandler from 'ui/routes/capacity-check'
import ConfirmationRouteHandler from 'ui/routes/confirmation'
import EstateRouteHandler from 'ui/routes/estate'
import ExecutorsRouteHandler from 'ui/routes/executors'
import FuneralRouteHandler from 'ui/routes/funeral'
import GiftsRouteHandler from 'ui/routes/gifts'
import GuardiansRouteHandler from 'ui/routes/guardians'
import InviteRouteHandler from 'ui/routes/invite'
import LoginRouteHandler from 'ui/routes/login'
import LoginJwtRouteHandler from 'ui/routes/login-jwt'
import LogoutRouteHandler from 'ui/routes/logout'
import NotFoundRouteHandler from 'ui/routes/not-found'
import PaymentRouteHandler from 'ui/routes/payment'
import PeopleRouteHandler from 'ui/routes/people'
import PrintAndSendRouteHandler from 'ui/routes/print-and-send'
import RequestResetPasswordRouteHandler from 'ui/routes/request-reset-password'
import RequestResetPasswordHelpRouteHandler from 'ui/routes/request-reset-password-help'
import ResetPasswordRouteHandler from 'ui/routes/reset-password'
import SectionCompletedRouteHandler from 'ui/routes/section-completed'
import SingleSignOnRouteHandler from 'ui/routes/single-sign-on'
import StartRouteHandler from 'ui/routes/start'
import SubscriptionRouteHandler from 'ui/routes/subscription'
import SummaryRouteHandler from 'ui/routes/summary'
import WillsRouteHandler from 'ui/routes/wills'

const handleScrolling = (location) => {
  const { hash } = location

  if (!hash) {
    setScrollPosition(0)
    return
  }

  // setTimeout hack to wait for components to render as DOM
  setTimeout(() => {
    const id = hash.replace('#', '')
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView()
    }
  }, 0)
}

const pageEnterHandler = (store, location) => {
  store.dispatch(closeMobileNav())
  store.dispatch(closeAccountMenu())

  // Scroll page to top on route change
  // except when there is a hash
  handleScrolling(location)
}

const Routes = ({ store }) => {
  useEffect(() => {
    pageEnterHandler(store, history.location)
    history.listen((location) => pageEnterHandler(store, location))
  }, [])

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(routeProps) => (
          <LoginRouteHandler
            layoutConfig={{
              isPublic: true,
              noIndex: true,
              hideNavigation: true,
              hideFooter: true,
              hideBorder: true,
              centerContentVertically: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/log-in"
        render={(routeProps) => (
          <LoginRouteHandler
            layoutConfig={{
              isPublic: true,
              noIndex: true,
              hideNavigation: true,
              hideFooter: true,
              hideBorder: true,
              centerContentVertically: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/log-in-jwt"
        render={(routeProps) => (
          <LoginJwtRouteHandler
            layoutConfig={{
              isPublic: true,
              noIndex: true,
              hideNavigation: true,
              hideFooter: true,
              hideBorder: true,
              centerContentVertically: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/logout"
        render={(routeProps) => (
          <LogoutRouteHandler
            layoutConfig={{
              isPublic: true,
              noIndex: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/about-yourself"
        render={(routeProps) => (
          <AboutYourselfRouteHandler
            layoutConfig={{
              routeInGuidedFlow: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/confirmation"
        render={(routeProps) => (
          <ConfirmationRouteHandler routeProps={routeProps} />
        )}
      />
      <Route
        exact
        path="/guardians"
        render={(routeProps) => (
          <GuardiansRouteHandler
            layoutConfig={{
              routeInGuidedFlow: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/guardians/:childId/:page"
        render={(routeProps) => (
          <GuardiansRouteHandler
            layoutConfig={{
              routeInGuidedFlow: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/gifts"
        render={(routeProps) => (
          <GiftsRouteHandler
            layoutConfig={{
              routeInGuidedFlow: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/estate"
        render={(routeProps) => (
          <EstateRouteHandler
            layoutConfig={{
              routeInGuidedFlow: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/executors"
        render={(routeProps) => (
          <ExecutorsRouteHandler
            layoutConfig={{
              routeInGuidedFlow: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/accounts-and-property"
        render={(routeProps) => (
          <AccountsAndPropertyRouteHandler
            layoutConfig={{
              routeInGuidedFlow: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/funeral"
        render={(routeProps) => (
          <FuneralRouteHandler
            layoutConfig={{
              routeInGuidedFlow: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/section-completed"
        render={(routeProps) => (
          <SectionCompletedRouteHandler
            layoutConfig={{
              hideFooter: true,
              routeInGuidedFlow: true,
              useDecorativeBackground: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/summary"
        render={(routeProps) => <SummaryRouteHandler routeProps={routeProps} />}
      />
      <Route
        path="/people"
        render={(routeProps) => <PeopleRouteHandler routeProps={routeProps} />}
      />
      <Route
        path="/account"
        render={(routeProps) => <AccountRouteHandler routeProps={routeProps} />}
      />
      <Route
        path="/payment"
        render={(routeProps) => (
          <PaymentRouteHandler
            layoutConfig={{
              hideNavigation: true,
              useDecorativeBackground: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/wills"
        render={(routeProps) => <WillsRouteHandler routeProps={routeProps} />}
      />
      <Route
        path="/print-and-send"
        render={(routeProps) => (
          <PrintAndSendRouteHandler routeProps={routeProps} />
        )}
      />
      <Route
        path="/invite"
        render={(routeProps) => <InviteRouteHandler routeProps={routeProps} />}
      />
      <Route
        path="/capacity-check"
        render={(routeProps) => (
          <CapacityCheckRouteHandler
            layoutConfig={{
              hideNavigation: true,
              hideBorder: true,
              hideFooter: true,
              centerContentVertically: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/purchase-subscription"
        render={(routeProps) => (
          <SubscriptionRouteHandler routeProps={routeProps} />
        )}
      />
      <Route
        path="/request-reset-password"
        render={(routeProps) => (
          <RequestResetPasswordRouteHandler
            layoutConfig={{
              isPublic: true,
              noIndex: true,
              hideFooter: true,
              hideBorder: true,
              centerContentVertically: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/request-reset-password-help"
        render={(routeProps) => (
          <RequestResetPasswordHelpRouteHandler
            layoutConfig={{
              isPublic: true,
              noIndex: true,
              hideFooter: true,
              hideBorder: true,
              centerContentVertically: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/reset-password"
        render={(routeProps) => (
          <ResetPasswordRouteHandler
            layoutConfig={{
              isPublic: true,
              noIndex: true,
              hideBorder: true,
              centerContentVertically: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <Route
        path="/sso"
        render={(routeProps) => (
          <SingleSignOnRouteHandler
            layoutConfig={{
              isPublic: true,
              hideNavigation: true,
              hideBorder: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
      <CompatRoute
        path="/start/:page"
        render={() => {
          return <StartRouteHandler />
        }}
      />
      <Route
        path="*"
        render={(routeProps) => (
          <NotFoundRouteHandler
            layoutConfig={{
              isPublic: true,
              hideBorder: true,
            }}
            routeProps={routeProps}
          />
        )}
      />
    </Switch>
  )
}

export default Routes
